import * as React from "react";
import { withPreviewResolver } from "gatsby-source-prismic";

const PreviewPage = ({ isPreview }) => {
  if (isPreview === false) return "Not a preview!";

  return <p>Loading</p>;
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: process.env.GATSBY_APP_REPO_NAME,
  lang: "*",
  fetchLinks: [],
  htmlSerializer: () => () => {},
  linkResolver: () => (doc) => {
    if(doc.uid){
      return `/${doc.lang}/${doc.uid}`;
    }

    return `/${doc.lang}`; 
  },
});
